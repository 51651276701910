<template>
  <product title="Amazfit 表带 炫彩系列"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="79"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    isSwitchSlogan
    buy-link="https://shop18009901.m.youzan.com/wscgoods/detail/3nu55qhujk6et"
  ></product>
</template>

<script>
import Product from '../Product'

export default {
  name: 'silicone-wristbands',
  components: {
    Product
  },
  data () {
    return {
      slogan: [
        '20mm',
        '20mm',
        '20mm',
        '20mm',
        '20mm',
        '22mm',
        '22mm',
        '22mm',
        '22mm',
        '22mm'
      ],
      colors: [
        '樱语粉',
        '幻夜黑',
        '活力橙',
        '沉静蓝',
        '丁香紫',
        '幻夜黑',
        '月相灰',
        '赤霞橙',
        '午夜蓝',
        '青空灰'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/10_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/10_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/10_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/11_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/11_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/11_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/12_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/12_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/12_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/13_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/13_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/13_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/14_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/14_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/14_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/15_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/15_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/15_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/16_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/16_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/16_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/17_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/17_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/17_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/18_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/18_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/18_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/19_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/19_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part01/19_pro_03.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        'https://huami-site.cdn.bcebos.com/files/amazfit/cn/sku/parts/part01/detail/mobile.jpg'
      ],
      paramImages: [
        'https://huami-site.cdn.bcebos.com/files/amazfit/cn/sku/parts/part01/detail/parameter.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
